import { createRouter, createWebHashHistory } from "vue-router";
import store from ".././store";
import authService from "@/services/auth.service";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import Subscriptions from "@/views/SubscriptionsOverview.vue";

const routes = [
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Dashboard",
    },
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Dashboard.vue"),
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Scraper",
    },
    path: "/scraper",
    name: "scraper",
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Scraper.vue"),
  },
  {
    meta: {
      title: "Company",
    },
    path: "/company",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Company.vue"),
  },
  {
    meta: {
      title: "Workflow",
    },
    path: "/workflow",
    name: "workflow",
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/WorkflowBuilder.vue"),
  },
  {
    meta: {
      title: "Invoices",
    },
    path: "/invoices",
    name: "invoices",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Invoices.vue"),
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Replenish",
    },
    path: "/replenish",
    name: "replenish",
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Replenish.vue"),
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Builder",
    },
    path: "/builder",
    name: "Builder",
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Builder.vue"),
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Features",
    },
    path: "/features",
    name: "features",
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Features.vue"),
  },
  {
    // Document title tag
    // We combine it with defaultDocumentTitle set in `src/main.js` on router.afterEach hook
    meta: {
      title: "Examples",
    },
    path: "/examples",
    name: "examples",
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Examples.vue"),
  },
  {
    meta: {
      title: "Profile",
    },
    path: "/profilev2",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Test.vue"),
  },
  {
    path: "/subscriptions",
    name: "subscriptions",
    component: Subscriptions,
    meta: {
      requiresAuth: true,
      title: "Subscriptions"
    }
  },
  {
    meta: {
      title: "Settings",
    },
    path: "/settings",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Alerts.vue"),
  },
  {
    meta: {
      title: "Pricing",
    },
    path: "/pricing",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Pricing.vue"),
  },
  {
    meta: {
      title: "Tables",
    },
    path: "/tables",
    name: "tables",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "tables" */ "@/views/Tables.vue"),
  },
  {
    meta: {
      title: "dash",
    },
    path: "/dash",
    name: "dash",
    component: () =>
      import(/* webpackChunkName: "forms" */ "@/views/Home.vue"),
  },
  {
    meta: {
      title: "Forms",
    },
    path: "/forms",
    name: "forms",
    component: () =>
      import(/* webpackChunkName: "forms" */ "@/views/Forms.vue"),
  },
  {
    path: '/auth/callback',
    name: 'auth-callback',
      component: () => import('@/components/AuthCallback.vue')
  },
  {
    meta: {
      title: "Ui",
    },
    path: "/ui",
    name: "ui",
    component: () => import(/* webpackChunkName: "ui" */ "@/views/Ui.vue"),
  },
  {
    meta: {
      title: "Responsive layout",
    },
    path: "/responsive",
    name: "responsive",
    component: () =>
      import(/* webpackChunkName: "responsive" */ "@/views/Responsive.vue"),
  },
  {
    meta: {
      title: "Login",
      fullScreen: true,
    },
    path: "/login",
    name: "login",
    component: () =>
      import(/* webpackChunkName: "login" */ "@/views/Login.vue"),
  },
  {
    meta: {
      title: "Register",
      fullScreen: true,
    },
    path: "/register",
    name: "register",
    component: () =>
      import(/* webpackChunkName: "register" */ "@/views/RegisterV2.vue"),
  },
  {
    meta: {
      title: "Error",
      fullScreen: true,
    },
    path: "/error",
    name: "error",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
  },
  {
    meta: {
      title: "Verify Email",
    },
    path: "/verify",
    name: "verify",
    component: () =>
      import(/* webpackChunkName: "error" */ "@/views/Verify.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { top: 0 };
  },
});

router.beforeEach((to, from, next) => {

  const text = {
    displayText: null,
    displayType: null,
    displayHeader: null,
  };
  store.dispatch("setDisplayMessage", text);

  const publicPages = ["/login", "/register", "/home", "/landing", "/auth/callback"];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("user");

  if (authRequired) {
    store.dispatch("auth/isExpired");
  }

  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {

    /**
   * Fingerpinting using to
   * prevent abuse and monitor
   * the system better
   */
    if (
      store.state &&
      store.state.auth &&
      store.state.auth.user &&
      store.state.auth.user.user
    ) {
      const fingerprintUser = store?.state?.auth?.user?.user?.fingerprint;
      const ip = store?.state?.auth?.user?.user?.ip;

      if (!ip || ip.length <= 0) {
        fetch('https://api.ipify.org/?format=json')
          .then(response => response.json())
          .then(data => {
            const ip = data.ip;
            authService.setIp(ip).then(() => {
              authService.getUser();
            });
          })
      }

      if (!fingerprintUser || fingerprintUser.length <= 0) {
        FingerprintJS.load()
          .then((fp) => fp.get())
          .then((result) => {
            const fp = result.visitorId;
            authService.setFingerprint(fp).then(() => {
              authService.getUser();
            });
          });
      }
    }

    next();
  }
});

export default router;
