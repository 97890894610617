<script setup>
import { useStore } from 'vuex'
import { computed } from 'vue'

const props = defineProps({
  username: {
    type: String,
    default: null
  },
  bg: {
    type: String,
    default: 'bg-gray-100 dark:bg-gray-800'
  },
  api: {
    type: String,
    default: 'api/avataaars'
  }
})

const store = useStore()

const avatar = computed(() => {
  const user = store.state.auth.user?.user;
  return user?.avatar || `https://api.dicebear.com/5.x/identicon/svg?seed=${props.username}`;
})

const name = computed(() => props.username ? props.username : store.state.userName)
</script>

<template>
  
  <div>
    <img
      :src="avatar"
      :alt="name"
      class="rounded-full block h-auto w-full max-w-full"
      :class="bg"
    >
  </div>
</template>
